import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { DataBrowser } from 'cccisd-laravel-assignment';
import Loader from 'cccisd-loader';
import SiteSelector from 'js/components/SiteSelector/index.js';
import { connect } from 'react-redux';
import { client } from 'cccisd-apollo';
import { loadGroups } from 'js/reducers/sites.js';
import deploymentsQuery from './deployments.graphql';

var Fortress = window.cccisd.fortress;
const showSiteSelector = !['siteAdmin'].includes(Fortress.user.acting.role.handle);

const ExportData = props => {
    const [prevAssignment, setPrevAssignment] = useState(props.assignmentId);
    const [deploymentIds, setDeploymentIds] = useState([]);
    const [loadingDeployments, setLoadingDeployments] = useState(false);

    useEffect(() => {
        if (props.groups.length === 0) {
            props.loadGroups();
        }
    }, []);

    useEffect(() => {
        setPrevAssignment(props.assignmentId);
    }, [props.assignmentId]);

    useEffect(() => {
        if (showSiteSelector) setLoadingDeployments(true);
        getDeployments();
    }, [props.checked]);

    const getDeployments = async () => {
        const siteIds = showSiteSelector ? props.checked : Fortress.user.acting.group_id;

        // console.log('showSiteSelector', showSiteSelector);
        // console.log('siteIds', siteIds); // why is this empty some times ??
        // console.log('props', props);
        setLoadingDeployments(true);
        const response = await client.query({
            query: deploymentsQuery,
            variables: {
                siteIds,
                assignmentId: props.assignmentId,
            },
            fetchPolicy: 'network-only',
            errorPolicy: 'all',
            cancelTag: 'report',
        });

        setDeploymentIds(response.data.flows.deploymentList.map(d => d.deploymentId));
        setLoadingDeployments(false);
    };

    // determine the evaluation cycles for this site

    if (prevAssignment !== props.assignmentId || loadingDeployments) {
        return <Loader loading />;
    }

    const actualDeploymentIds = showSiteSelector && deploymentIds ? deploymentIds : props.deploymentWhitelist;

    // console.log('deploymentIds', deploymentIds);
    // console.log('props whitelist', props.deploymentWhitelist);
    // console.log('actualDeploymentIds', actualDeploymentIds);

    // if (actualDeploymentIds.length === 0) {
    //     return <Loader loading />;
    // }
    return (
        <div className="container-fluid">
            {showSiteSelector && <SiteSelector />}

            <p />
            <DataBrowser
                key={`key_${actualDeploymentIds.join('-')}`}
                projectId={props.projectId}
                showDeploymentIds={actualDeploymentIds}
            />
        </div>
    );
};

ExportData.propTypes = {
    projectId: PropTypes.number,
    assignmentId: PropTypes.number,
    deploymentWhitelist: PropTypes.array,
    // redux
    groups: PropTypes.array,
    checked: PropTypes.array,
    loadGroups: PropTypes.func,
};

const mapStateToProps = state => ({
    groups: state.app.sites.groups,
    checked: state.app.sites.checked,
});

export default connect(mapStateToProps, { loadGroups })(ExportData);
