import React from 'react';
import PropTypes from 'prop-types';
import ReportCenter from 'cccisd-report-center';

export default class TabView extends React.Component {
    static propTypes = {
        handle: PropTypes.string,
    };

    render() {
        return (
            <div>
                <ReportCenter handle={this.props.handle} />
            </div>
        );
    }
}
