import React from 'react';
import style from './style.css';
import Tabs from '../Tabs';
import TabView from './TabView';
import ViewData from './ViewData';
import { client } from 'cccisd-apollo';
import Loader from 'cccisd-loader';
import userSiteListQuery from '../../graphql/userSiteList.graphql';
import deploymentWhitelistQuery from '../../graphql/deploymentWhitelist.graphql';

const user = window.cccisd.fortress.user.acting;
const isUber = user.data_type === 'uberadmin';
const licenses = {
    dart: !isUber ? user.group.type_data.dartLicense : 1,
    wfi: !isUber ? user.group.type_data.wfiLicense : 1,
    tom: !isUber ? user.group.type_data.tomLicense : 1,
};
const toolPermissions = !isUber ? JSON.parse(user.role_data.toolPermissions) : [];
const pagePermissions = !isUber ? JSON.parse(user.role_data.permissions) : [];

const appdefs = window.cccisd.appDefs.app;

const handleMap = {
    uberadmin: { general: 'generaluber', wfi: 'wfi-ezuber', dart: 'dartuber', tom: 'tom2.0uber' },
    collaboratorAdmin: {
        general: 'generalcollab',
        wfi: 'wfi-ezcollab',
        dart: 'dartcollab',
        tom: 'tom2.0collab',
    },
    organizationAdmin: {
        general: 'generalorg',
        wfi: 'wfi-ezorg',
        dart: 'dartorg',
        tom: 'tom2.0org',
    },
    agencyAdmin: {
        general: 'generalagency',
        wfi: 'wfi-ezagency',
        dart: 'dartagency',
        tom: 'tom2.0agency',
    },
    siteAdmin: { general: 'generalsite', wfi: 'wfi-ezsite', dart: 'dartsite', tom: 'tom2.0site' },
};

export default class Reports extends React.Component {
    state = {
        wfiCCDeploymentWhitelist: [],
        wfiCGDeploymentWhitelist: [],
        wfiTMDeploymentWhitelist: [],
        wfiYTDeploymentWhitelist: [],
        tomDeploymentWhitelist: [],
        dartDeploymentWhitelist: [],
        isLoading: true,
    };

    componentDidMount = () => {
        this.getDeploymentList();
    };

    getDeploymentList = async () => {
        const siteListResponse = await client.query({
            query: userSiteListQuery,
            variables: {},
            fetchPolicy: 'network-only',
            errorPolicy: 'all',
        });

        const siteListArray = await siteListResponse.data.groups.siteList.map(g => {
            return g.group.groupId;
        });

        const response = await client.query({
            query: deploymentWhitelistQuery,
            variables: {
                siteIds: siteListArray,
            },
            fetchPolicy: 'network-only',
            errorPolicy: 'all',
        });

        // await new Promise(res => setTimeout(res, 5000)); // use for testing race condition bug CCCPLAT-346
        const wfiCCArray = response.data.flows.wfiCareCoordinator.map(d => {
            return d.deploymentId;
        });

        const wfiCGArray = response.data.flows.wfiCaregiver.map(d => {
            return d.deploymentId;
        });

        const wfiTMArray = response.data.flows.wfiTeamMember.map(d => {
            return d.deploymentId;
        });

        const wfiYTArray = response.data.flows.wfiYouth.map(d => {
            return d.deploymentId;
        });

        const tomArray = response.data.flows.tom.map(d => {
            return d.deploymentId;
        });

        const dartArray = response.data.flows.dart.map(d => {
            return d.deploymentId;
        });

        this.setState({
            wfiCCDeploymentWhitelist: wfiCCArray,
            wfiCGDeploymentWhitelist: wfiCGArray,
            wfiTMDeploymentWhitelist: wfiTMArray,
            wfiYTDeploymentWhitelist: wfiYTArray,
            tomDeploymentWhitelist: tomArray,
            dartDeploymentWhitelist: dartArray,
            isLoading: false,
        });
    };

    getTabs = () => {
        const tabList = [
            { name: 'wfi', title: 'WFI-EZ' },
            { name: 'tom', title: 'TOM 2.0' },
            { name: 'dart', title: 'DART' },
        ]
            .map(rc => ({
                name: rc.name,
                title: rc.title,
                content: <TabView handle={handleMap[user.data_type][rc.name]} />,
            }))
            .filter(tab => {
                if (!isUber) {
                    // if the group is allowed
                    if (licenses[tab.name]) {
                        // if the user has permissions set
                        if (toolPermissions && toolPermissions.length > 0) {
                            return toolPermissions.includes(tab.name);
                        }
                        return true;
                    }
                } else {
                    // if uberadmin
                    return true;
                }
                return false;
            });

        tabList.unshift({
            name: 'general',
            title: 'General',
            content: <TabView handle={handleMap[user.data_type].general} />,
        });

        if (isUber || (pagePermissions && pagePermissions.length > 0 && pagePermissions.includes('viewData'))) {
            let viewDataTabs = this.getDataTabs();
            let content = <Tabs level={1} slim tabList={this.getDataTabs()} />;
            if (viewDataTabs.length === 0) {
                content = (
                    <div>
                        <h2>No tool access</h2>
                    </div>
                );
            }
            if (this.state.isLoading) {
                content = (
                    <div style={{ marginTop: '20px' }}>
                        <Loader loading type="inline" />
                    </div>
                );
            }
            tabList.push({
                name: 'viewData',
                title: 'View Data',
                content,
            });
        }

        return tabList;
    };

    getDataTabs = () => {
        const dataTabs = [
            {
                name: 'wfiCareCoord',
                title: 'WFI-EZ Care Coordinator',
                license: 'wfi',
                content: (
                    <ViewData
                        key="wfiCC"
                        projectId={appdefs.wfiProjectId}
                        assignmentId={appdefs.wfiCareCoordAssignmentId}
                        deploymentWhitelist={this.state.wfiCCDeploymentWhitelist}
                    />
                ),
            },
            {
                name: 'wfiCaregiver',
                title: 'WFI-EZ Caregiver',
                license: 'wfi',
                content: (
                    <ViewData
                        key="wfiCG"
                        projectId={appdefs.wfiProjectId}
                        assignmentId={appdefs.wfiCaregiverAssignmentId}
                        deploymentWhitelist={this.state.wfiCGDeploymentWhitelist}
                    />
                ),
            },
            {
                name: 'wfiTeamMember',
                title: 'WFI-EZ Team Member',
                license: 'wfi',
                content: (
                    <ViewData
                        key="wfiTM"
                        projectId={appdefs.wfiProjectId}
                        assignmentId={appdefs.wfiTeamMemberAssignmentId}
                        deploymentWhitelist={this.state.wfiTMDeploymentWhitelist}
                    />
                ),
            },
            {
                name: 'wfiYouth',
                title: 'WFI-EZ Youth',
                license: 'wfi',
                content: (
                    <ViewData
                        key="wfiY"
                        projectId={appdefs.wfiProjectId}
                        assignmentId={appdefs.wfiYouthAssignmentId}
                        deploymentWhitelist={this.state.wfiYTDeploymentWhitelist}
                    />
                ),
            },
            {
                name: 'tom',
                title: 'TOM 2.0',
                license: 'tom',
                content: (
                    <ViewData
                        key="tom"
                        projectId={appdefs.tomProjectId}
                        assignmentId={appdefs.tomAssignmentId}
                        deploymentWhitelist={this.state.tomDeploymentWhitelist}
                    />
                ),
            },
            {
                name: 'dart',
                title: 'DART',
                license: 'dart',
                content: (
                    <ViewData
                        key="dart"
                        projectId={appdefs.dartProjectId}
                        assignmentId={appdefs.dartAssignmentId}
                        deploymentWhitelist={this.state.dartDeploymentWhitelist}
                    />
                ),
            },
        ].filter(tab => {
            if (!isUber) {
                // if the group is allowed
                if (licenses[tab.license]) {
                    // if the user has permissions set
                    if (toolPermissions && toolPermissions.length > 0) {
                        return toolPermissions.includes(tab.license);
                    }
                    return true;
                }
            } else {
                // if uberadmin
                return true;
            }
            return false;
        });

        return dataTabs;
    };

    render() {
        if (this.state.isLoading) {
            return <Loader loading />;
        }
        return (
            <div className={style.pageMargin}>
                <h2>Reports</h2>
                <p>Generate reports for the youth roster variables and WFAS tools.</p>
                <br />
                <Tabs tabId="wrapStatTools" tabList={this.getTabs()} />
            </div>
        );
    }
}
